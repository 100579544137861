<template>
  <div class="jumbotron rounded-border">
    <div class="jumbotron-body sidebar-sensitive">
      <div class="container">
        <div class="flex-container">
          <div class="text-copy">
            <h1 class="jumbotron-title">
              The Home of Tirador
            </h1>
            <h2 class="jumbotron-subtitle">
              What's a Tirador Deal? A Tirador Deal goes beyond the traditional buy and sell of securities.
              It's a deal that's created by you or ordered from the market. It's a deal that's unique to you and your needs.
              It can be a future behaviour of a stock, a future behaviour of a stock index, a future behaviour of a commodity, or a future behaviour of a currency.
              Or an outcome of a future sports event, political event, or any event that can be quantified. You can create a deal based on those outcomes.
            </h2>
          </div>
          <div class="button-container">
            <button class="big-button">Create or Order a Deal</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted } from 'vue';
import NavBarHeaderVue from "@/components/NavBarHeader";

export default {
  name: 'Tirador',
  props:
    {
    msg: String
  }
  , title: 'Beef',
  setup() {
    onMounted(() => {
      var sidebarSensitiveDivs = document.querySelectorAll(".sidebar-sensitive");
      for (let i = 0; i < sidebarSensitiveDivs.length; i++) {
        sidebarSensitiveDivs[i].addEventListener('click', function(event) {
          NavBarHeaderVue.methods.closeNav(event);
        });
      }
    })
  }
}
</script>
<style scoped>
.jumbotron {
  background-color: #e9ecef;
  margin: 60px auto; /* This centers the section horizontally */
  margin-top: 100px; /* Adjust this value based on the height of your navbar header */
  padding: 20px;
  width: 80%;
  border: 2px solid #000; /* This sets the border width to 2px, style to solid, and color to black */
  height: 85vh; /* This sets the height of the jumbotron to 100% of the viewport height */

}
.jumbotron-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* This will make the jumbotron-body take up the full height of its parent */
  padding-top: 0; /* Remove the padding-top */
  padding-bottom: 0; /* Remove the padding-bottom */
}
.jumbotron-title {
  font-size: 3rem;
  font-weight: 300;
  line-height: 4rem;
}
.jumbotron-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2rem;
}
.rounded-border {
  border: 1px solid #000;
  border-radius: 10px;
}
.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.text-copy {
  flex: 1;
}
.button-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.big-button {
  font-size: 2rem;
  padding: 1rem 2rem;
  background: linear-gradient(to right, #007bff, #00b0ff);
  border-radius: 10px;
  border: none;
  color: white;
}
.big-button:hover {
  background: linear-gradient(to right, #00b0ff, #007bff);
}
.container {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* This will make the container take up the full height of the viewport */
}
body {
  padding-top: 100px; /* Adjust this value based on the height of your navbar menu */
}
</style>
